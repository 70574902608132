import { AxiosError } from "axios";
import queryString from "query-string";
import { useEffect, useState } from "react";

import { adaAPI } from "services/api";
import { datadogLogs } from "services/datadog";

import { type ResponseSearchResultItem } from "./ResponseListItem/ResponseSearchResult";

export const MAX_RESPONSES_TO_SHOW = 50;

async function fetchResponseSearchResults(
  searchQuery: string,
  abortSignal: AbortSignal,
): Promise<ResponseSearchResultItem[]> {
  try {
    const res = await adaAPI.request({
      method: "GET",
      url: `/responses/filter?${queryString.stringify({
        userinput: searchQuery,
        page: 1,
        pageSize: MAX_RESPONSES_TO_SHOW + 1,
      })}`,
      abortSignal,
    });

    return res.data.responses as ResponseSearchResultItem[];
  } catch (error) {
    // QueryParseErrors mean the user sent a malformed search query
    if (
      error instanceof AxiosError &&
      error.response?.data.type !== "QueryParseError"
    ) {
      // otherwise, allow the error to propagate
      datadogLogs.logger.warn("responses/filter search failure", {
        userInput: searchQuery,
        error,
      });
      throw error;
    }
  }

  return [];
}

export function useCancellableSearch() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<
    ResponseSearchResultItem[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchSearchResults() {
      if (searchQuery.trim().length) {
        setIsLoading(true);
        const results = await fetchResponseSearchResults(
          searchQuery,
          abortController.signal,
        );
        setIsLoading(false);
        setSearchResults(results);
      } else {
        setSearchResults(null);
      }
    }

    setTimeout(fetchSearchResults, 500);

    return () => {
      abortController.abort();
    };
  }, [searchQuery]);

  return {
    searchQuery,
    setSearchQuery,
    isLoading,
    searchResults,
  };
}
